<template>
  <div class="blog-page blog-page-posts-show">
    <div v-if="post">
      <div class="blog-page--hero">
        <h1 class="blog-page--page-title" v-html="post.title" />
        <h3 class="blog-page--page-date blog-page--preview-date">
          {{ post.date | prettyDate }}
        </h3>
      </div>

      <div class="container">
        <div class="blog-page--preview-large blog-page--preview-large-hero-merge" :style="getPostImage(post)">
&nbsp;
        </div>
      </div>

      <div class="blog-page--post-container">
        <div class="container" v-html="post.content">
&nbsp;
        </div>

        <div class="container">
          <router-link :to="localizedRoute(`/posts`)" class="btn btn-primary link blog-page--preview-link">
            Back to posts
          </router-link>
        </div>
      </div>

      <div class="blog-page--entries" v-if="relatedPosts">
        <div class="container">
          <h2>
            Related Posts
          </h2>

          <div class="row row--entries">
            <div class="col col-md-6 col-lg-4" v-for="(post) in relatedPosts" :key="post.id">
              <div class="blog-page--preview-small">
                <router-link :to="localizedRoute(`/posts/${post.slug}`)" class="blog-page--preview-small-bg" :style="getPostImage(post)">
                  &nbsp;
                </router-link>

                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date">
                    {{ post.date | prettyDate }}
                  </h4>

                  <h3 class="blog-page--preview-title">
                    <router-link :to="localizedRoute(`/posts/${post.slug}`)">
                      <span v-html="post.title" />
                    </router-link>
                  </h3>

                  <p class="blog-page--preview-excerpt" v-html="$options.filters.truncate( post.excerpt, 175, '...' )">
&nbsp;
                  </p>

                  <router-link :to="localizedRoute(`/posts/${post.slug}`)" class="blog-page--preview-link">
                    Learn More&nbsp;<span class="slide-right">&rtrif;</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- div v-else>
      <h2>
        No post found.
      </h2>
    </div -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PostsShow',
  serverPrefetch () {
    return this.fetchPost()
  },
  computed: {
    ...mapGetters({
      post: 'wordpress/currentPost'
    }),
    relatedPosts () {
      return null
    }
  },
  filters: {
    prettyDate: function (date) {
      const newDate = new Date(date)
      const dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('en-GB', dateFormat)
    }
  },
  // Client-side only
  mounted () {
    if (!this.posts) {
      this.fetchPost()
    }
  },
  methods: {
    fetchPost () {
      return this.$store.dispatch('wordpress/loadPost', { slug: this.$route.params.slug })
    },
    getPostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.sourceUrl != null) {
        return {
          backgroundImage: `url(${post.featuredImage.node.sourceUrl})`
        }
      } else {
        return {
          backgroundColor: '#ccc'
        }
      }
    }
  }
}
</script>
